<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{  $t('label_email_automatic_password_change') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card

                    class="mb-0"
            >
                <b-card-header class="pb-50">
                    <h5>{{ $t('label_email_automatic_password_change') }}</h5>
                </b-card-header>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_email_title')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_email_title')"
                                class="required"
                        >
                            <b-form-input

                                    v-model="itemData.default_automatic_email_title"
                                    :placeholder="$t('label_email_title')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_email_automatic_password_change')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_email_automatic_password_change')"

                        >
                            <b-form-textarea
                                    :placeholder="$t('label_email_automatic_password_change')"
                                    :state="getValidationState(validationProps)"
                                    rows="7"
                                    v-model="itemData.default_automatic_email_text"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_set_the_frequency_of_automatic_password_change_generation')"
                            rules="required|integer"
                    >
                        <b-form-group

                                :label="$t('label_set_the_frequency_of_automatic_password_change_generation')"
                                class="required"
                        >
                            <b-form-input
                                    type="number"
                                    v-model="itemData.number_of_days_change_auto_password"
                                    placeholder=""
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <b-alert  variant="info" show>
                        <div class="">{{$t('message_change_password_period')}}</div>
                    </b-alert>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_numbers_of_days')"
                            rules="required|integer"
                    >
                        <b-form-group

                                :label="$t('label_numbers_of_days')"
                                class="required"
                        >
                            <b-form-input
                                    type="number"
                                    v-model="itemData.number_of_days"
                                    :placeholder="$t('label_numbers_of_days')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>



                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_save') : $t('label_save') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
    </div>
</template>

<script>


    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {
        BCardHeader, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox,  BAlert,
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'


    export default {
        name: "editItem.vue",
        components: {
            BCardHeader,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,
            BAlert,

            ValidationProvider,
            ValidationObserver,

        },
        data() {
            return {

                PREFIX: 'automatic_email',

                itemData: {},

                action: 'adding',

                blankItemData: {
                    default_automatic_email_title: '',
                    default_automatic_email_text: '',
                    number_of_days_change_auto_password: '',
                    number_of_days: '',
                },

            }
        },
        created() {
            this.initData();
        },
        methods: {
            initData() {

                this.async('get', '/general_settings/' + this.PREFIX, {params:{}}, function (resp) {
                    let editedItem = resp.data;
                    let item = Object.assign({}, this.blankItemData);
                    // item.id = user.id;
                    for (let prop in item) {
                        if (editedItem.hasOwnProperty(prop)) {
                            item[prop] = editedItem[prop];
                        }
                    }
                    this.itemData = item;
                });

            },
            onSubmit() {
                if (this.action == 'adding') {
                    this.async('post', '/general_settings/' + this.PREFIX, this.itemData, function (resp) {
                        this.initData();
                    });
                }
            },

        }
    }
</script>

<!--<style scoped>-->

<!--</style>-->